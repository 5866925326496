/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate, useParams } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

import validator from 'validator';

// @mui material icons
import CheckIcon from '@mui/icons-material/Check';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import CoverLayout from '../components/BasicLayout';

// Images
import bgImage from 'assets/images/ulfix-background1.jpeg';
import { Grid } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import brandPixcua from 'assets/images/pixcua.png';
import { useForm } from 'hooks/useForm';
import { useEffect, useState } from 'react';
import { planAtributos } from 'actions/Auth/authTypes';
import { getPlanByAlias, postNuevoUsuario } from 'actions/Auth/auth';
import { useDispatch,useSelector } from 'react-redux';
import { RootStore } from 'store/Store';

function SignUp(): JSX.Element {
	const params = useParams();

	const dispatch = useDispatch();
	const { registerState } = useSelector((state: RootStore) => state.auth);
	const navigate = useNavigate();
	const tipoPlan: planAtributos = {
		id: null,
		nombre: '',
		cantidad: null,
		precio: null,
		alias: '',
		caracteristicas: '',
		stripe_id: '',
		periodo: '',
		estaActivo: false,
	};

	const [plan, setPlan] = useState(tipoPlan);
	const [miPlan, setMiPlan] = useState('');
	const caracteristicas = plan.caracteristicas.split(', ');

	const [errorMsg, setError] = useState<string>('');
	interface iNuevoUsuarioFormulario {
		nombre: string;
		rfc: string;
		email: string;
		password: string;
		confimpassword: string;
	}
	const usuario: iNuevoUsuarioFormulario = {
		nombre: '',
		rfc: '',
		email: '',
		password: '',
		confimpassword: '',
	};
	//Uso de hook useForm para manejo de campos en el formulario
	const [formValues, handleInputChange] = useForm(usuario);
	const { nombre, rfc, email, password, confimpassword } = formValues;

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (isFormValid()) {
			dispatch<any>(
				postNuevoUsuario({
					nombre: nombre,
					rfc: rfc,
					email: email,
					password: password,
					plan_id: plan.id,
				})
			);
		}
	};
	const isFormValid = (): boolean => {
		const re =
			/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

		if (!rfc.match(re)) {
			setError('El campo RFC es incorrecto');
			return false;
		} else if (!validator.isEmail(email)) {
			setError('El campo email es incorrecto');
			return false;
		} else if (password !== confimpassword) {
			setError('Las contraseñas no coinciden');
			return false;
		}

		setError('');
		return true;
	};

	useEffect(() => {
		if (
			!params.plan ||
			['emprendedor', 'pyme', 'empresarial', 'freemium'].includes(params.plan)
		) {
			if (!params.plan) setMiPlan('emprendedor');
			else setMiPlan(params.plan);
		} else navigate('/signup');

		if (miPlan) {
			const data = getPlanByAlias(miPlan);
			data
				.then((respuesta) => {
					console.log('miPlan',miPlan);
					if (respuesta.status === 200) {
						setPlan(respuesta.data);
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response.data.message);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response.data.message);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response.data.message);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response.data.message);
					} else {
						console.log(error.response.data.message);
					}
				});
			
		}
	}, [navigate, miPlan, params.plan]);

	//Navigate->logIn si registro es exitoso
	useEffect(() => {
		if (registerState.isRegister) {
			navigate('/login');
		}
	}, [registerState, navigate]);

	return (
		<CoverLayout image={bgImage}>
			<Grid container justifyContent='center' alignItems='center'>
				<MDAvatar
					variant='square'
					style={{ marginTop: '2rem', width: '9rem' }}
					src={brandPixcua}
					alt='Avatar'
				/>
			</Grid>

			<Grid
				display={'flex'}
				alignItems={'center'}
				justifyContent={'center'}
				style={{ marginTop: '2.5rem', flexWrap: 'wrap' }}
			>
				<Card style={{ minWidth: '300px', margin: '5px' }}>
					<MDBox
						variant='gradient'
						bgColor='primary'
						borderRadius='lg'
						coloredShadow='success'
						mx={2}
						mt={-3}
						p={3}
						mb={1}
						textAlign='center'
					>
						<MDTypography variant='h4' fontWeight='medium' color='white'>
							Registrate
						</MDTypography>
						<MDTypography display='block' variant='button' color='white'>
							Inicia tu registro
						</MDTypography>
					</MDBox>
					<MDBox pt={4} pb={3} px={3}>
						<MDBox display='flex' alignItems='center' style={{ minWidth: '290px' }}>
							<MDTypography
								fontWeight='light'
								color='text'
								sx={{ userSelect: 'none', ml: -1, fontSize: '12px' }}
							>
								&nbsp;&nbsp;Todos los campos con * son obligatorios&nbsp;
							</MDTypography>
						</MDBox>
						<MDBox component='form' role='form' onSubmit={handleSubmit}>
							<MDBox mb={2}>
								<MDInput
									type='text'
									label='Nombre o razón social'
									variant='standard'
									fullWidth
									name='nombre'
									value={nombre}
									onChange={handleInputChange}
									required
								/>
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									type='text'
									label='RFC'
									variant='standard'
									fullWidth
									name='rfc'
									value={rfc}
									onChange={handleInputChange}
									error={errorMsg === 'El campo RFC es incorrecto' ? true : false}
									required
								/>
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									type='email'
									label='Email'
									variant='standard'
									fullWidth
									name='email'
									value={email}
									onChange={handleInputChange}
									required
								/>
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									type='password'
									label='Contraseña'
									variant='standard'
									fullWidth
									name='password'
									value={password}
									onChange={handleInputChange}
									required
								/>
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									type='password'
									label='Confirma contraseña'
									variant='standard'
									fullWidth
									name='confimpassword'
									value={confimpassword}
									onChange={handleInputChange}
									error={errorMsg === 'Las contraseñas no coinciden' ? true : false}
									required
								/>
							</MDBox>
							<MDBox mb={2}>
								{errorMsg && (
									<MDTypography
										variant='text'
										color='error'
										fontWeight='light'
										textGradient
										style={{ fontSize: '12px' }}
									>
										{errorMsg}.
									</MDTypography>
								)}
							</MDBox>

							<MDBox mt={4} mb={1}>
								{!registerState.loading ? (
									<MDButton variant='gradient' color='primary' fullWidth type='submit'>
										Continuar
									</MDButton>
								) : (
									<MDButton variant='gradient' color='primary' fullWidth disabled>
										Registrando...
									</MDButton>
								)}
							</MDBox>

							<MDBox mt={3} mb={1} textAlign='center'>
								<MDTypography variant='button' color='info'>
									¿Ya tienes una cuenta?{' '}
									<MDTypography
										component={Link}
										to='/login'
										variant='button'
										color='primary'
										fontWeight='medium'
										textGradient
									>
										Entrar
									</MDTypography>
								</MDTypography>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
				<Card style={{ minWidth: '300px', margin: '5px' }}>
					<MDBox
						variant='gradient'
						bgColor='light'
						borderRadius='lg'
						coloredShadow='success'
						mx={2}
						mt={-3}
						p={3}
						mb={1}
						textAlign='center'
					>
						<MDTypography variant='h4' fontWeight='medium' color='primary'>
							Prueba Gratuita
						</MDTypography>
					</MDBox>
					<MDBox
						pt={1}
						pb={3}
						px={3}
						style={{ display: 'flex', flexDirection: 'column' }}
					>
						<MDTypography
							type='text'
							color='primary'
							fontWeight='medium'
							textGradient
						>
							Plan {plan.nombre}
						</MDTypography>
						<MDTypography fontWeight='regular' sx={{ userSelect: 'none', ml: -1 }}>
							&nbsp;&nbsp;Gratis para nuevos usuarios&nbsp;
						</MDTypography>						
						<MDTypography type='text' color='text' textGradient sx={{ mt: 1 }}>
							{plan.cantidad} Timbres mensuales disponibles.
						</MDTypography>						
					</MDBox>
					<MDBox pb={3} px={4} style={{ display: 'flex', flexDirection: 'column' }}>
						{caracteristicas.map((item, i) => (
							<MDTypography
								key={i}
								variant='button'
								fontWeight='regular'
								sx={{ userSelect: 'none' }}
							>
								<CheckIcon /> &nbsp;&nbsp;{item}&nbsp;
							</MDTypography>
						))}
							<MDTypography
							variant='button'
							fontWeight='regular'
							sx={{ userSelect: 'none' }}
							style={{ marginTop: '10px', maxWidth: '25rem' }}
							>
								Todos nuestros planes contratados tienen vigencia de un mes. En caso de no utilizar
								todos los timbres, estos serán acumulados en su siguiente periodo. El
								costo del plan seleccionado una vez terminado el periodo de prueba de 7 días es de
								${plan.precio}.00 mensuales
							</MDTypography>
					</MDBox>
				</Card>
			</Grid>
		</CoverLayout>
	);
}

export default SignUp;
