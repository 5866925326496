/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// react-router components
import { useLocation, Link } from 'react-router-dom';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDBadge from 'components/MDBadge';
import { ToastContainer } from 'react-toastify';

// Material Dashboard 2 PRO React TS examples components
import Breadcrumbs from 'examples/Breadcrumbs';
import NotificationItem from 'examples/Items/NotificationItem';

// Custom styles for DashboardNavbar
import {
	navbar,
	navbarContainer,
	navbarRow,
	navbarIconButton,
	navbarDesktopMenu,
	navbarMobileMenu,
} from 'examples/Navbars/DashboardNavbar/styles';

// Material Dashboard 2 PRO React context
import {
	useMaterialUIController,
	setTransparentNavbar,
	setMiniSidenav,
	setOpenConfigurator,
} from 'context';
import { Divider, MenuItem, Modal } from '@mui/material';
import { getEmpresaById, getEmpresas } from 'actions/MiCuenta/miCuenta';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import CambioEmpresa from 'pantallas/MiCuenta/CambioEmpresa';

// Declaring prop types for DashboardNavbar
interface Props {
	absolute?: boolean;
	light?: boolean;
	isMini?: boolean;
}

function DashboardNavbar({ absolute, light, isMini }: Props): JSX.Element {
	const { tipo_usuario_id } = useSelector((state: RootStore) => state.auth);
	const [nombreEmpresa, setNombreEmpresa] = useState('');
	const [empresasData, setEmpresasData] = useState([]);
	const [empresaAcambiar, setEmpresaAcambiar] = useState();
	// modal
	const [open, setOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const handleCloseModal = () => setOpenModal(false);
	const handleOpen = () => setOpenModal(true);
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const [navbarType, setNavbarType] = useState<
		'fixed' | 'absolute' | 'relative' | 'static' | 'sticky'
	>();
	const [controller, dispatch] = useMaterialUIController();
	const {
		miniSidenav,
		transparentNavbar,
		fixedNavbar,
		openConfigurator,
		darkMode,
	} = controller;
	const [openMenu, setOpenMenu] = useState<any>(false);
	const route = useLocation().pathname.split('/').slice(1);

	const cambioDeEmpresa = (empresa: any) => {
		setOpenModal(true);
		handleToggle();
		setEmpresaAcambiar(empresa);
	};

	useEffect(() => {
		// Setting the navbar type
		if (fixedNavbar) {
			setNavbarType('sticky');
		} else {
			setNavbarType('static');
		}

		// A function that sets the transparent state of the navbar.
		function handleTransparentNavbar() {
			setTransparentNavbar(
				dispatch,
				(fixedNavbar && window.scrollY === 0) || !fixedNavbar
			);
		}

		/** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
		window.addEventListener('scroll', handleTransparentNavbar);

		// Call the handleTransparentNavbar function to set the state with the initial value.
		handleTransparentNavbar();

		// Remove event listener on cleanup
		return () => window.removeEventListener('scroll', handleTransparentNavbar);
	}, [dispatch, fixedNavbar]);

	// useEffect para obtener empresas a cambiar

	useEffect(() => {
		const dataEmpresas = getEmpresas();
		dataEmpresas
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setEmpresasData(respuesta.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					console.log(error.response.data.message);
				}
			});
	}, []);

	const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	const handleConfiguratorOpen = () =>
		setOpenConfigurator(dispatch, !openConfigurator);
	const handleOpenMenu = (event: any) => setOpenMenu(event.currentTarget);
	const handleCloseMenu = () => setOpenMenu(false);
	const { empresa_id } = useSelector((state: RootStore) => state.auth);
	useEffect(() => {
		const dataEmpresa = getEmpresaById(empresa_id);
		dataEmpresa
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setNombreEmpresa(respuesta.data.nombre);
				}
			})
			.catch((error) => {
				setNombreEmpresa('');
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					console.log(error.response.data.message);
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [empresa_id]);

	// Render the notifications menu
	const renderMenu = () => (
		<Menu
			anchorEl={openMenu}
			anchorReference={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={Boolean(openMenu)}
			onClose={handleCloseMenu}
			sx={{ mt: 2 }}
			style={{ minWidth: '370px' }}
		>
			<div style={{ fontWeight: 'bold', marginLeft: '15px', paddingTop: '5px' }}>
				Cambiar de empresa a...
			</div>
			{empresasData.map((item) => (
				<div key={item.id} onClick={() => cambioDeEmpresa(item)}>
					{item.nombre === nombreEmpresa ? (
						<MenuItem style={{ backgroundColor: '#2facc86f' }}>
							{item.nombre}
						</MenuItem>
					) : (
						<MenuItem>{item.nombre}</MenuItem>
					)}
				</div>
			))}
			<Divider />
			{/* <MenuItem>Suscripciones</MenuItem> */}
			<Link
				to={'/configuracion/suscripciones'}
				// target='_blank'
				rel='noreferrer'
				// sx={{ textDecoration: 'none' }}
			>
				<MenuItem>Suscripciones</MenuItem>
			</Link>
		</Menu>
	);

	// Styles for the navbar icons
	const iconsStyle = ({
		palette: { dark, white, text },
		functions: { rgba },
	}: {
		palette: any;
		functions: any;
	}) => ({
		color: () => {
			let colorValue = light || darkMode ? white.main : dark.main;

			if (transparentNavbar && !light) {
				colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
			}

			return colorValue;
		},
	});

	return (
		<AppBar
			position={absolute ? 'absolute' : navbarType}
			color='inherit'
			sx={(theme) =>
				navbar(theme, { transparentNavbar, absolute, light, darkMode })
			}
		>
			<Toolbar sx={navbarContainer}>
				<MDBox
					color='inherit'
					mb={{ xs: 1, md: 0 }}
					sx={(theme) => navbarRow(theme, { isMini })}
				>
					<IconButton
						sx={navbarDesktopMenu}
						onClick={handleMiniSidenav}
						size='small'
						disableRipple
						style={{ marginRight: '1rem' }}
					>
						<Icon fontSize='medium' sx={iconsStyle}>
							{miniSidenav ? 'menu_open' : 'menu'}
						</Icon>
					</IconButton>
					<Breadcrumbs
						icon='home'
						title={route[route.length - 1]}
						route={route}
						light={light}
					/>
					<IconButton
						size='small'
						disableRipple
						color='inherit'
						sx={navbarMobileMenu}
						onClick={handleMiniSidenav}
					>
						<Icon sx={iconsStyle} fontSize='medium'>
							{miniSidenav ? 'menu_open' : 'menu'}
						</Icon>
					</IconButton>
				</MDBox>
				{isMini ? null : (
					<MDBox sx={(theme) => navbarRow(theme, { isMini })}>
						{/* <MDBox pr={1}>
							<MDInput label='Search here' />
						</MDBox> */}
						<MDBox color={light ? 'white' : 'inherit'}>
							{/* <Link to='/authentication/sign-in/basic'>
								<IconButton sx={navbarIconButton} size='small' disableRipple>
									<Icon sx={iconsStyle}>account_circle</Icon>
								</IconButton>
							</Link> */}
							{/* <IconButton
								size='small'
								disableRipple
								color='inherit'
								sx={navbarMobileMenu}
								onClick={handleMiniSidenav}
							>
								<Icon sx={iconsStyle} fontSize='medium'>
									{miniSidenav ? 'menu_open' : 'menu'}
								</Icon>
							</IconButton> */}
							{/* <IconButton
								size='small'
								disableRipple
								color='inherit'
								sx={navbarIconButton}
								onClick={handleConfiguratorOpen}
							>
								<Icon sx={iconsStyle}>settings</Icon>
							</IconButton> */}
							{tipo_usuario_id === 1 || tipo_usuario_id === 2 ? (
								<IconButton
									size='small'
									color='inherit'
									sx={navbarIconButton}
									onClick={handleOpenMenu}
									style={{ borderRadius: '5px' }}
								>
									{/* <MDBadge badgeContent={9} color='error' size='xs' circular>
									<Icon sx={iconsStyle}>notifications</Icon> Nombre de la Empresa
								</MDBadge> */}
									<MDBadge size='xs'>
										{/* <Icon sx={iconsStyle}>account_circle</Icon> Ulfix LLC */}
										{nombreEmpresa}
										<Icon sx={iconsStyle} style={{ marginRight: '5px' }}>
											expand_more
										</Icon>
									</MDBadge>
								</IconButton>
							) : (
								nombreEmpresa
							)}

							{renderMenu()}
						</MDBox>
						{/* Mi barra de busqueda */}
					</MDBox>
				)}
			</Toolbar>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				style={{
					maxHeight: '750px',
					maxWidth: '1350px',
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
				}}
			>
				<MDBox>
					<CambioEmpresa
						closeModal={() => {
							handleCloseModal();
						}}
						handleOpen={handleOpen}
						empresaAcambiar={empresaAcambiar}
					/>
				</MDBox>
			</Modal>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				style={{ zIndex: '3000' }}
			/>
		</AppBar>
	);
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
	absolute: false,
	light: false,
	isMini: false,
};

export default DashboardNavbar;
