import { axiosClient, getToken } from 'Config/axiosConfig';

//Definicion Controller
export const getRegimenes = async () => {
	const token = localStorage.getItem('pxc-tkn') || '';
	return await axiosClient.get(`/regimenFiscal`, {
		headers: {
			Authorization: `${token}`,
		},
	});
};
export const getEntidades = async () => {
	const token = localStorage.getItem('pxc-tkn') || '';
	return await axiosClient.get(`/entidades`, {
		headers: {
			Authorization: `${token}`,
		},
	});
};
export const getEntidadesByPais = async (pais: string) => {
	const token = localStorage.getItem('pxc-tkn') || '';
	return await axiosClient.get(`/entidades?c_pais=${pais}`, {
		headers: {
			Authorization: `${token}`,
		},
	});
};
export const getEmpresaById = async (empresaId: number) => {
	const token = localStorage.getItem('pxc-tkn') || '';
	return await axiosClient.get(`/empresas/${empresaId}`, {
		headers: {
			Authorization: `${token}`,
		},
	});
};
export const getEmpresaInfoById = async (empresaId: number) => {
	return await axiosClient.get(`/empresaInfo/${empresaId}`, {});
};
export const getUserById = async (userId: number) => {
	const token = localStorage.getItem('pxc-tkn') || '';
	return await axiosClient.get(`/usuarios/${userId}`, {
		headers: {
			Authorization: `${token}`,
		},
	});
};
export const patchUsuarioData = async (data: {}, userId: number) => {
	const token = localStorage.getItem('pxc-tkn') || '';
	return await axiosClient.patch(`/usuarios/${userId}`, data, {
		headers: {
			Authorization: `${token}`,
		},
	});
};
export const patchEmpresaData = async (data: {}, userId: number) => {
	console.log('ejecuntando peticion...');
	const token = localStorage.getItem('pxc-tkn') || '';
	return await axiosClient.patch(`/empresas/${userId}`, data, {
		headers: {
			Authorization: `${token}`,
		},
	});
};
export const patchImagenEmpresa = async (file: any, userId: number) => {
	console.log('ejecuntando peticion...', file);
	const token = localStorage.getItem('pxc-tkn') || '';
	return await axiosClient.post(`/empresas/imagen/${userId}`, file, {
		headers: {
			Authorization: `${token}`,
		},
	});
};
export const getEmpresaLogoByLogo = async (logoName: string) => {
	const token = localStorage.getItem('pxc-tkn') || '';
	return await axiosClient.get(`/empresas/imagen/${logoName}`, {
		headers: {
			Authorization: `${token}`,
		},
	});
};
export const postEmpresaLogoByLogo = async (logoFile: any) => {
	const token = localStorage.getItem('pxc-tkn') || '';
	return await axiosClient.post(`empresas/imagen/`, logoFile, {
		headers: {
			Authorization: `${token}`,
		},
	});
};

export const getEmpresas = async () => {
	await getToken();
	return await axiosClient.get(`/empresas`);
};
export const postCambioEmpresas = async (empresa_Id: any) => {
	await getToken();
	return await axiosClient.post(`/cambia_empresa`, empresa_Id);
};
