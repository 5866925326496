export const getBase64ImageFromURL = (url: string, attempts = 3) => {
	return new Promise((resolve, reject) => {
		const loadImage = (attempt: number) => {
			const img = new Image();
			img.setAttribute('crossOrigin', 'anonymous');
			
			img.onload = () => {
				const canvas = document.createElement('canvas');
				canvas.width = img.width;
				canvas.height = img.height;
				const ctx = canvas.getContext('2d');
				if (ctx) {
					ctx.drawImage(img, 0, 0);
					const dataURL = canvas.toDataURL('image/png');
					resolve(dataURL);
				} else {
					reject(new Error("Failed to get canvas context"));
				}
			};
			
			img.onerror = () => {
				if (attempt <= 1) {
					console.error(`Error loading image, failed after ${3 - attempt + 1} attempts.`);
					reject(new Error('Image loading failed after multiple attempts.'));
				} else {
					console.warn(`Retrying image load, attempt ${3 - attempt + 1}`);
					loadImage(attempt - 1);  // Reattempt loading the image
				}
			};
			
			img.src = `${url}?t=${new Date().getTime()}`;

		};
		
		loadImage(attempts);
	});
};

