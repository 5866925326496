import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import Grid from '@mui/material/Grid';
import QrCode from '@mui/icons-material/QrCode';
import { timbrarComprobante } from 'actions/Comprobante/comprobante';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer } from 'react-toastify';

function Clonar(props: any): JSX.Element {
	const dispatch = useDispatch();

	const { loading } = useSelector((state: RootStore) => state.loading);

	const clonarItem = () => {
		//comunicacion con el servicio
		console.log('timbrando:::', props.item.uuid);
		props.clonarComprobante(props.item.id);
	};

	return (
		<React.Fragment>
			<Box sx={style}>
				<MDAvatar
					bgColor='primary'
					variant='circular'
					style={{
						alignItems: 'center',
						margin: 'auto',
						justifyContent: 'center',
						textAlign: 'center',
						fontSize: 100,
						width: 128,
						height: 128,
					}}
				>
					<QrCode />
				</MDAvatar>
				<Typography
					id='modal-modal-description'
					sx={{ mt: 2 }}
					style={{
						alignItems: 'center',
						margin: '1rem',
						justifyContent: 'center',
						textAlign: 'center',
					}}
				>
					¿Desea Clonar el comprobante?
				</Typography>
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='fcenter'
					sx={{ marginTop: '2rem', marginBottom: '2rem' }}
				>
					{loading ? (
						<>
							<MDButton
								size='small'
								color='primary'
								sx={{ margin: '1rem' }}
								onClick={clonarItem}
								disabled
							>
								<CircularProgress color='secondary' size={15} />
								<span style={{ marginLeft: '8px' }}>Cargando...</span>
							</MDButton>
							<MDButton
								size='small'
								color='light'
								sx={{ margin: '1rem' }}
								onClick={() => {
									props.closeModal();
								}}
							>
								Cancelar
							</MDButton>
						</>
					) : (
						<>
							<MDButton
								size='small'
								color='primary'
								sx={{ margin: '1rem' }}
								onClick={clonarItem}
							>
								SI
							</MDButton>
							<MDButton
								size='small'
								color='light'
								sx={{ margin: '1rem' }}
								onClick={() => {
									props.closeModal();
								}}
							>
								NO
							</MDButton>
						</>
					)}
				</Grid>
			</Box>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</React.Fragment>
	);
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 800,
	bgcolor: 'background.paper',
	border: '2px',
	boxShadow: 24,
	p: 4,
};

export default Clonar;
