// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'components/Tables/DataTable';

import Wizard from 'pantallas/Comprobantes/components/wizard/index';
import { CSVLink } from 'react-csv';

// Data
import { useEffect, useState } from 'react';
import SplitButton from './Data/acciones';
import { getEmpresaInfoById } from 'actions/MiCuenta/miCuenta';
import {
	iMiEmpresaInfoToPDF,
	initMiEmpresaFormulario,
} from 'actions/MiCuenta/miCuentaTypes';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from 'store/Store';
import moment from 'moment';
import ProgressBar from './components/ProgressBar/ProgressBar';
import { Dispatch } from 'redux';
import { axiosClient, getToken } from 'Config/axiosConfig';
import {
	LoadingDispatchTypes,
	LOADING_END,
	START_LOADING,
} from 'actions/Loading/loadingTypes';
import PXLoader from 'components/PXLoader/PXLoader';
import { numeroAMoneda } from 'helpers/aMoneda';
import { ToastContainer } from 'react-toastify';
import EstatusPagoPagado from './components/EstatusPago/EstatusPagoPagado';
import EstatusPagoNoPagado from './components/EstatusPago/EstatusPagoNoPagado';

function Comprobantes(): JSX.Element {
	const { empresa_id } = useSelector((state: RootStore) => state.auth);
	const { loading } = useSelector((state: RootStore) => state.loading);
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = (event: object, reason: string) => {
		if (reason && reason == 'backdropClick') return;
		setOpen(false);
		setComprobanteToUpdate(null);
	};

	//useState que guarda la Data obtenida del servidor

	const [comprobantesListData, setComprobantesListData] = useState([]);
	const [comprobanteToUpdate, setComprobanteToUpdate] = useState();
	const [miEmpresa, setMiEmpresa] = useState<iMiEmpresaInfoToPDF>(
		initMiEmpresaFormulario
	);

	//GetEmpresaInfoById
	useEffect(() => {
		if (empresa_id) {
			const dataEmpresa = getEmpresaInfoById(empresa_id);
			dataEmpresa
				.then((respuesta) => {
					if (respuesta.status === 200) {
						setMiEmpresa(respuesta.data);
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response.data.message);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response.data.message);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response.data.message);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response.data.message);
					} else {
						console.log(error.response.data.message);
					}
				});
		}
	}, [empresa_id]);

	const isMyDataValid = () => {
		if (
			!miEmpresa.nombre ||
			!miEmpresa.rfc ||
			!miEmpresa.codigo_postal ||
			!miEmpresa.calle ||
			!miEmpresa.no_exterior ||
			!miEmpresa.colonia ||
			!miEmpresa.localidad ||
			!miEmpresa.estado ||
			!miEmpresa.pais
		) {
			return false;
		}

		return true;
	};

	const tipoComprobante = (tipoSerieId: number): string => {
		if (tipoSerieId === 1) {
			return 'Factura';
		} else if (tipoSerieId === 2) {
			return 'Recibo de Honorarios';
		}
	};

	//Estuctura de datos para tabla
	const datosTabla = {
		columns: [
			{ Header: 'UuId', accessor: 'UUID', width: '80px' },
			{ Header: 'Serie', accessor: 'Serie', align: 'center' },
			{ Header: 'Folio', accessor: 'Folio', width: '80px' },
			{ Header: 'Cliente', accessor: 'Cliente', width: '400px' },
			{ Header: 'Fecha', accessor: 'Fecha' },
			{ Header: 'Tipo', accessor: 'Tipo' },
			{ Header: 'Subtotal', accessor: 'Subtotal' },
			{ Header: 'I.V.A', accessor: 'IVA' },
			{ Header: 'Total', accessor: 'Total' },
			{ Header: 'Pagado', accessor: 'Pagado' },
			{ Header: 'Estatus', accessor: 'Estatus' },
			{ Header: 'Acciones', accessor: 'Acciones' },
		],
		rows: comprobantesListData
			.map((item) => {
				return {
					UUID: item.uuid ? item.uuid : '',
					Serie: item.serie,
					Folio: item.folio,
					Cliente: item.nombre,
					Fecha: moment(item.fecha).format('DD-MM-YYYY'),
					Tipo: tipoComprobante(item.tipo_comprobante_id),
					Subtotal: numeroAMoneda(item.subtotal),
					IVA: numeroAMoneda(item.iva),
					Total: numeroAMoneda(item.total),
					Pagado: item.pagado ? <EstatusPagoPagado /> : <EstatusPagoNoPagado />,
					Estatus: (
						<>
							<ProgressBar progressId={item.estatus_comprobante_id} />
						</>
					),
					Acciones: (
						<>
							<SplitButton
								item={item}
								handleOpen={handleOpen}
								setComprobanteToUpdate={setComprobanteToUpdate}
								setComprobantesListData={setComprobantesListData}
								isMyDataValid={isMyDataValid}
							/>
						</>
					),
				};
			})
			.reverse(),
	};
	//Obtener Comprobantes
	useEffect(() => {
		const getComprobantesAsync = () => {
			return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
				await getToken();
				dispatch({ type: START_LOADING });
				axiosClient
					.get(`/comprobantes`)
					.then((respuesta) => {
						setComprobantesListData(respuesta.data);
						dispatch({ type: LOADING_END });
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 400) {
							console.log('error400', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 403) {
							console.log('error403', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 404) {
							console.log('error404', error.response.data);
							setComprobantesListData([]);
							dispatch({ type: LOADING_END });
						} else {
							console.log(error.response.data);
							dispatch({ type: LOADING_END });
						}
					});
			};
		};
		dispatch<any>(getComprobantesAsync());
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pt={6} pb={3}>
				<Card>
					<MDBox p={3} lineHeight={1}>
						<MDTypography variant='h5' fontWeight='medium' color='primary'>
							Comprobantes
						</MDTypography>
						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='flex-end'
						>
							<MDButton size='small' color='primary' onClick={handleOpen}>
								+ Agregar
							</MDButton>
							<CSVLink
								data={comprobantesListData
									.map((d) => {
										if (d.estatus_comprobante_id === 1) {
											return {
												serie: d.serie,
												Folio: d.folio,
												Uuid: d.uuid,
												Cliente: d.nombre,
												Fecha: d.fecha,
												Subtotal: d.subtotal,
												IVA: d.iva,
												Total: d.total,
												Estatus: 'Cancelado',
											};
										}
										if (d.estatus_comprobante_id === 2) {
											return {
												serie: d.serie,
												Folio: d.folio,
												Uuid: d.uuid,
												Cliente: d.nombre,
												Fecha: d.fecha,
												Subtotal: d.subtotal,
												IVA: d.iva,
												Total: d.total,
												Estatus: 'Creado',
											};
										}
										if (d.estatus_comprobante_id === 3) {
											return {
												serie: d.serie,
												Folio: d.folio,
												Uuid: d.uuid,
												Cliente: d.nombre,
												Fecha: d.fecha,
												Subtotal: d.subtotal,
												IVA: d.iva,
												Total: d.total,
												Estatus: 'Timbrado',
											};
										}
										if (d.estatus_comprobante_id === 4) {
											return {
												serie: d.serie,
												Folio: d.folio,
												Uuid: d.uuid,
												Cliente: d.nombre,
												Fecha: d.fecha,
												Subtotal: d.subtotal,
												IVA: d.iva,
												Total: d.total,
												Estatus: 'Timbrado & Enviado',
											};
										}
										return {
											serie: d.serie,
											Folio: d.folio,
											Uuid: d.uuid,
											Cliente: d.nombre,
											Fecha: d.fecha,
											Subtotal: d.subtotal,
											IVA: d.iva,
											Total: d.total,
											Estatus: d.estatus_comprobante_id,
										};
									})
									.reverse()}
								filename='Comprobantes.csv'
							>
								<MDButton size='small' sx={{ marginLeft: '5px', border: '1px solid' }}>
									+ Exportar CSV
								</MDButton>
							</CSVLink>
						</Grid>
					</MDBox>
					<MDBox display={'flex'} justifyContent={'center'}>
						<MDBox style={{ width: '98%' }}>
							<DataTable table={datosTabla} canSearch />
						</MDBox>
					</MDBox>
					{loading && (
						<>
							<MDBox m={4}>
								<PXLoader />
							</MDBox>
						</>
					)}
				</Card>
			</MDBox>

			<Modal
				open={open}
				disableScrollLock={true}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<MDBox sx={style}>
					{
						<Wizard
							comprobanteToUpdate={comprobanteToUpdate}
							handleClose={handleClose}
							setComprobantesListData={setComprobantesListData}
						/>
					}
				</MDBox>
			</Modal>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</DashboardLayout>
	);
}
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90vw',
	maxWidth: 1080,
};
export default Comprobantes;
