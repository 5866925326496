import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import Grid from '@mui/material/Grid';
import QrCode from '@mui/icons-material/QrCode';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { timbrarComprobante } from 'actions/Comprobante/comprobante';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import { postCambioEmpresas } from 'actions/MiCuenta/miCuenta';
import { LOADING_END, START_LOADING } from 'actions/Loading/loadingTypes';
import { startChecking } from 'actions/Auth/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function CambioEmpresa(props: any): JSX.Element {
	const dispatch = useDispatch();

	const { loading } = useSelector((state: RootStore) => state.loading);
	const navigate = useNavigate();

	const cambiarEmpresa = () => {
		//comunicacion con el servicio
		dispatch({ type: START_LOADING });
		const cambioEmpresas = postCambioEmpresas({
			empresa_id: props.empresaAcambiar.id,
		});
		cambioEmpresas
			.then((respuesta) => {
				if (respuesta.status === 200) {
					localStorage.setItem('pxc-tkn', respuesta.data.token);
					dispatch<any>(startChecking());

					// dispatch<any>(getComprobantesA(setComprobantesListData));
					setTimeout(() => {
						props.closeModal(); //Cerrar modal
						dispatch({ type: LOADING_END });

						dispatch<any>(startChecking());
						// dispatch<any>(startChecking());
					}, 2000);
					setTimeout(() => {
						navigate('/');
						// props.closeModal(); //Cerrar modal
						window.location.reload();
					}, 1500);
				}
			})
			.catch((error) => {
				setTimeout(() => {
					// props.closeModal(); //Cerrar modal
					window.location.reload();
					props.closeModal(); //Cerrar modal
				}, 1500);
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.message}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.message}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 403) {
					console.log('error403---->');
					console.log(error.response.data.message);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.message}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data.message);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.message}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else {
					console.log(error.response.data.message);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.message}`,
						showConfirmButton: false,
						timer: 3500,
					});
				}
			});
	};

	return (
		<React.Fragment>
			<Box sx={style}>
				<MDAvatar
					bgColor='primary'
					variant='circular'
					style={{
						alignItems: 'center',
						margin: 'auto',
						justifyContent: 'center',
						textAlign: 'center',
						fontSize: 100,
						width: 128,
						height: 128,
					}}
				>
					<SwitchAccountIcon />
				</MDAvatar>
				<Typography
					id='modal-modal-description'
					sx={{ mt: 2 }}
					style={{
						alignItems: 'center',
						margin: '1rem',
						justifyContent: 'center',
						textAlign: 'center',
					}}
				>
					¿Desea cambiar a la empresa
					<br />{' '}
					<span style={{ fontWeight: 'bold' }}>{props.empresaAcambiar.nombre}</span>?
				</Typography>
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='fcenter'
					sx={{ marginTop: '2rem', marginBottom: '2rem' }}
				>
					{loading ? (
						<>
							<MDButton
								size='small'
								color='primary'
								sx={{ margin: '1rem' }}
								onClick={cambiarEmpresa}
								disabled
							>
								<CircularProgress color='secondary' size={15} />
								<span style={{ marginLeft: '8px' }}>Cargando...</span>
							</MDButton>
							<MDButton
								size='small'
								color='light'
								sx={{ margin: '1rem' }}
								onClick={() => {
									props.closeModal();
								}}
							>
								Cancelar
							</MDButton>
						</>
					) : (
						<>
							<MDButton
								size='small'
								color='primary'
								sx={{ margin: '1rem' }}
								onClick={cambiarEmpresa}
							>
								SI
							</MDButton>
							<MDButton
								size='small'
								color='light'
								sx={{ margin: '1rem' }}
								onClick={() => {
									props.closeModal();
								}}
							>
								NO
							</MDButton>
						</>
					)}
				</Grid>
			</Box>
			{/* <ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/> */}
		</React.Fragment>
	);
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 800,
	bgcolor: 'background.paper',
	border: '2px',
	boxShadow: 24,
	p: 4,
};

export default CambioEmpresa;
